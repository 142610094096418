.filter-container {
  margin-top: 30px;
  margin-bottom: 40px;
  height: 80px;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.filter-input {
  height: 45px;
  background-color: var(--secondary-color);
  color: var(--light-gray-50);
  font-size: 16px;
  border: 1px solid var(--light-gray-150);
  border-radius: 10px;
  width: 250px;
  padding: 12px 5px 12px 20px;
}

.filter-input:first-of-type {
  margin-right: 55px;
}

.filter-input::placeholder {
  color: var(--light-gray);
}
