.sales-by-date-container {
  height: 390px;
  padding: 25px 15px 50px 30px;
}

.sales-by-date-title {
  font-size: 24px;
  margin-bottom: 5px;
}

.sales-by-date-period {
  color: var(--light-gray-50);
}

.sales-by-date-data {
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.sales-by-date-quantity-value {
  font-size: 36px;
}

.sales-by-date-quantity-label {
  font-size: 18px;
  color: var(--light-green);
  display: block;
  margin-bottom: 10px;
}

.sales-by-date-quantity-description {
  font-size: 18px;
  color: var(--light-gray-100);
}

.sales-by-date-chart {
  width: 890px;
}
