.sales-table-container {
  padding: 30px 35px;
  margin-bottom: 50px;
}

.sales-table-title {
  font-size: 18px;
}

.sales-table {
  width: 100%;
  margin-top: 30px;
  border-spacing: 0;
}

.sales-table thead th {
  text-align: left;
  height: 55px;
  font-size: 16px;
}

.sales-table thead th:first-of-type {
  padding-left: 30px;
}

.sales-table tbody tr {
  transition: background 0.5s;
}

.sales-table tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}

.sales-table tbody tr:hover {
  background-color: var(--light-gray-150);
}

.sales-table tbody td {
  height: 55px;
  border-top: 1px solid var(--light-gray-125);
  font-size: 18px;
}

.sales-table tbody td:first-of-type {
  padding-left: 30px;
}
