* {
  box-sizing: border-box;
}

:root {
  --primary-color: #283142;
  --secondary-color: #1b2531;
  --white-color: #ffffff;
  --light-color: #e9e9e9;
  --light-gray-50: #9aaabe;
  --light-gray-100: #949494;
  --light-gray-125: #72849a;
  --light-gray-150: #384459;
  --light-green: #08b976;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--secondary-color);
  color: var(--light-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: var(--light-color);
}

.base-card {
  background: var(--primary-color);
  border-radius: 10px;
}
