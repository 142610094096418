.pie-chart-card {
  width: 30%;
  padding-top: 35px;
  display: flex;
}

.apexcharts-legend.apexcharts-align-center {
  flex-direction: column;
  align-items: center;
}
